<template>
  <div class="tips" v-if="show">
    {{ title }}
    <span class="x" @click="close">×</span>
  </div>
</template>

<script>
  export default {
    name: "Tips",
    data() {
      return {
        title: "此为临时链接，仅用于预览，将在短期失效",
        show: true,
      };
    },
    methods: {
      close() {
        this.show = false;
      },
    },
  };
</script>

<style lang="scss" scoped>
  .tips {
    background: #c6e0f7;
    color: #8c97a2;
    font-size: 12px;
    padding: 10px;
    position: relative;
    .x {
      position: absolute;
      right: 10px;
      line-height: 1;
      font-size: 20px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
</style>

<template>
  <div v-if="canShow">
    <Tips />
    <div class="tuji">
      <swiper ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, index) in list" :key="index">
          <img :src="item.src" class="pic" />
          <div class="pagination">
            <span class="current">{{ index + 1 }}</span>
            /
            <span class="total">{{ list.length }}</span>
          </div>
          <div class="tuji-title">
            {{ item.title }}
          </div>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
  import { getPreviewDate } from "@/utils/api";
  import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
  import "swiper/swiper-bundle.css";
  import { wxShare } from "@/utils/wxShare";
  import Tips from "@/components/tips";
  export default {
    name: "previewtuji",
    components: {
      Swiper,
      SwiperSlide,
      Tips,
    },
    directives: {
      swiper: directive,
    },
    data() {
      return {
        canShow: false,
        path: "kczPhotosDetail",
        id: "",
        swiperOptions: {
          autoHeight: true,
        },
        list: [],
        share_config: {},
      };
    },
    methods: {
      async getPreviewDate(id) {
        const res = await getPreviewDate(id);
        const data = res.data.data;
        this.list = data.materials.gallery.map(val => {
          return {
            title: val.title,
            src: val.src,
          };
        });
        this.share_config = data.share_config;
        this.canShow = true;
        document.title = "预览-" + data.title;
        //wxShare(this.share_config.title, this.share_config.share_img, this.share_config.share_link, this.share_config.summary);
        wxShare("快艺学APP", "https://sharekcz.cztv.tv/cbs.png", "https://sharekcz.cztv.tv/index", "常州，教我如何不想她");
      },
    },
    created() {
      this.id = this.$route.query.id;
      if (this.id) {
      } else {
        this.$router.push({ name: "index" });
      }
      this.getPreviewDate(this.id);
    },
  };
</script>

<style lang="scss">
  .tuji {
    background-color: #000;
    padding-bottom: 2rem;
    min-height: calc(100vh - 2rem);
    .swiper-container {
      width: 100%;
      .swiper-slide {
        width: 100%;
        min-height: calc(100vh - 4rem);
        .pic {
          width: 100%;
          height: auto;
        }
        .pagination {
          margin: 0.2rem auto;
          width: 9.2rem;
          font-size: 0.59rem;
          font-weight: bold;
          color: #d6d6d8;
        }
        .tuji-title {
          margin: 0.2rem auto;
          width: 9.2rem;
          font-size: 0.32rem;
          line-height: 0.53rem;
          color: #999999;
        }
      }
    }
  }
</style>

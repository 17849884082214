import http from "@/utils/request.js";
const baseURL = process.env.NODE_ENV === "test" ? "https://kan0519.cztv.tv/api/v1" : "https://kcz.cztv.tv/api/v1";
const czhURL = process.env.NODE_ENV === "test" ? "https://mp.netfishing.cn/api" : "https://apiczh.cztv.tv/api";
import { setStore, getStore, clearStore } from "@/utils/storage";
export function getArticleDetail(articleId) {
  return http({
    url: baseURL + `/article/${articleId}`,
    method: "get",
  });
}

export function getCommit(articleId) {
  return http({
    url: baseURL + `/article/${articleId}/comments`,
    method: "get",
  });
}

export function getTuijian(articleId) {
  return http({
    url: baseURL + `/article/${articleId}/related`,
    method: "get",
  });
}

export function getWenzheng(id) {
  return http({
    url: baseURL + `/wenzheng/detail/${id}`,
    method: "get",
  });
}

export function getCzh(hash_id) {
  return http({
    url: czhURL + `/media_account/${hash_id}`,
    method: "get",
  });
}

export function getCzhTimeline(hash_id, categoryId) {
  return http({
    url: czhURL + `/media_account/${hash_id}/timeline`,
    method: "get",
    params: {
      category_id: categoryId,
    },
  });
}

export function getCzhArticle(hash_id) {
  return http({
    url: czhURL + `/post/${hash_id}`,
    mehtod: "get",
  });
}

export function getCzhTuijian(hash_id) {
  return http({
    url: czhURL + `/post/${hash_id}/related`,
    mehtod: "get",
  });
}

export function getCzhCommit(hash_id) {
  return http({
    url: czhURL + `/post/${hash_id}/comments`,
    mehtod: "get",
  });
}

export function getCzhRecommend() {
  return http({
    url: czhURL + `/timeline/recommend_accounts`,
    mehtod: "get",
  });
}

export function getAdvert(key) {
  return http({
    url: baseURL + `/ad/${key}`,
    method: "get",
  });
}

export function getTvChannel() {
  return http({
    url: baseURL + `/channel/tv`,
    method: "get",
  });
}

export function getWeekTime() {
  return http({
    url: baseURL + `/program/week`,
    mehtod: "get",
  });
}

export function getProgram(channel_id, date) {
  return http({
    url: baseURL + `/program/index`,
    mehtod: "get",
    params: {
      channel_id,
      date,
    },
  });
}

export function getFmChannel() {
  return http({
    url: baseURL + `/channel/fm`,
    method: "get",
  });
}

export function getVoteDetail(id) {
  return http({
    url: baseURL + `/articlevote/getone/${id}`,
    method: "get",
  });
}

export function dovote(params) {
  return http({
    url: baseURL + `/articlevote/dovote`,
    method: "post",
    data: params,
  });
}

export function getPreviewDate(uuid) {
  return http({
    url: baseURL + `/article/${uuid}/preview`,
    method: "get",
  });
}

export function getSpecial(specialId) {
  return http({
    url: baseURL + `/special/${specialId}`,
    method: "get",
  });
}

export function getSpecialArticle(specialId, category_id, page) {
  return http({
    url: baseURL + `/special/${specialId}/articles`,
    mehtod: "get",
    params: {
      category_id,
      page,
    },
  });
}

export function adddata(data, deviceToken, deviceType) {
  return http({
    url: baseURL + "/form/data/store",
    headers: {
      Authorization: getStore("accessToken"),
      "device-token": deviceToken,
      "device-type": deviceType,
    },
    method: "post",
    data: data,
  });
}
export function getform(id) {
  return http({
    url: baseURL + "/form/show/" + id,
    method: "get",
  });
}
export function getFormInfo(id) {
  return http({
    url: baseURL + `/form/data/${id}`,
    mehtod: "get",
  });
}

export function videoUp(data) {
  return http({
    url: baseURL + `/image/upload`,
    headers: {
      Authorization: getStore("accessToken"),
      // Authorization:
      //   "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9rY3ouY3p0di50dlwvYXBpXC92MVwvdXNlclwvbG9naW5cL25vcm1hbCIsImlhdCI6MTYyMDcwMTI5OSwiZXhwIjoxNjIwODc0MDk5LCJuYmYiOjE2MjA3MDEyOTksImp0aSI6IjhtUnJMdmhSd0EyOW1WMDciLCJzdWIiOjEsInBydiI6IjFjNjNlNDhkOTc3OTAyZjBkZmM0ZTFmNGVmYmMwOWFkMzYyZmZlYjgiLCJ1dWlkIjoiMWFlZTU1YjUtODU1MC0zZGM1LTkzMGYtMDQyYTFkNjljZmUwIiwibmlja25hbWUiOiJva1x1NjYzNVx1NzlmMDEiLCJhdmF0YXIiOiJodHRwczpcL1wva2F2YXRhci5vc3MtY24tc2hhbmdoYWkuYWxpeXVuY3MuY29tXC8xXC8yMDIwMTIxN1wvamNuU2UxRDNjdTlUeEo1SXNuOWQ2Z041UHZxeGFNTlZWeUdRWnRhUS5wbmciLCJnZW5kZXIiOjEsInN0YXR1cyI6dHJ1ZSwibW9iaWxlX2NvbmZpcm1lZCI6dHJ1ZSwiaWRfbnVtYmVyX2NvbmZpcm1lZCI6dHJ1ZX0.hgVAGHt4TdWB0nmhhfPAS52EdqUi2B_gN-jGpeNngUj4pRARkIGi5J1qaFUDOyMvLV4KG6g5DJX_ezq0wfLduQcY68dqMf-0Z55bX5ADUsnfH4OTRs-0iRaBTb2V6z5Pmn69xWtUmpcH8jt1nqBTdoa2qcdqgTePiwzbVLwANcqRFFunCVkKuSFLnZbUBTaoFM8_pPqz1XMjtgftztYueIS_bLsxAJm8AO4CA7tHpIe_hXs8hPQhx8L3hSvb4_zhAjHLRv5OuFPUzyEzynM7-EY9ljMPb1MJWVn8iXpTpTuw30zzqBxxdeoRkiXwkiTcoSyhKGUnt7Oi0KJZzyw27g",
      "Content-Type": "multipart/form-data",
    },
    method: "post",
    data,
  });
}
